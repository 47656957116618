import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const NotFoundStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100vh;
  padding: 0 30px;
  text-align: center;

  @media (max-width: ${breakpoints.screenSM}) {
    padding: 0 20px;
  }

  .title {
    margin-bottom: 2%;
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;

    @media (max-width: ${breakpoints.screenLG}) {
      margin-bottom: 30px;
    }
  }

  .link {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    opacity: 0;
    will-change: opacity;
    animation: ${fadeIn} 0.8s cubic-bezier(0.7, 0, 0.3, 1) forwards;

    svg {
      width: 2.2vw;
      height: auto;
      transform: rotate(-145deg);

      @media (max-width: ${breakpoints.screenLG}) {
        width: auto;
      }
    }

    span {
      position: relative;

      :after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0.1em;
        background-color: ${({ theme }) => theme.textPrimary};
        height: 0.1em;
        width: 100%;
        transform: scaleX(0);
        transform-origin: bottom right;
        will-change: transform;
        transition: transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
      }
    }
  }

  .btn-back {
    border: 0;
    background-color: transparent;
  }

  a:hover span:after,
  .btn-back:hover span:after {
    transform: scaleX(1);
    transform-origin: bottom left;

    @media (max-width: ${breakpoints.screenLG}) {
      transform: scaleX(0);
    }
  }
`

export { NotFoundStyled }
