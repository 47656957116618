import React from 'react'
import { Link } from 'gatsby'
import { useTheme } from 'styled-components'

import SEO from '../components/SEO'
import Header from '../components/Header'
import ArrowUpRight from '../components/icons/ArrowUpRight'

import { TitleS, BodyM } from '../styles/Typography'
import { NotFoundStyled } from '../styles/NotFoundStyled'

const NotFound = () => {
  const theme = useTheme()

  return (
    <>
      <SEO title="Not Found" />
      <Header textColor={theme.backgroundDark} />
      <NotFoundStyled>
        <TitleS className="title">Oops, this page does not exist</TitleS>
        <Link to="/">
          <BodyM className="link" weight={600}>
            <ArrowUpRight />
            <span>go back home</span>
          </BodyM>
        </Link>
      </NotFoundStyled>
    </>
  )
}

export default NotFound
